import { useContext } from 'react'
import { RouterStore } from 'mobx-react-router'
import { MobXProviderContext } from 'mobx-react'

import { DseStore } from '../stores'

export const useStores = (): {
  routerStore: RouterStore
  dseStore: DseStore
} => useContext(MobXProviderContext as any)

export const useRouterStore = () => useStores().routerStore

export const useDseStore = () => useStores().dseStore
