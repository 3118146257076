import React from 'react'
import styled from 'styled-components'

const Page: React.FC = ({ children }) => {
  return (
    <ContentWrapper>
      <Content className="page">{children}</Content>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.main`
  padding: 10px 10px;
`

const Content = styled.div``

export { Page }
