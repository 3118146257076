import React from 'react'
import { useParams } from 'react-router'
import { useObserver } from 'mobx-react'
import { loader } from 'graphql.macro'
import { useMutation } from 'react-apollo'
import styled from 'styled-components'
import { map } from 'styled-components-breakpoint'
import { scroller as scroll } from 'react-scroll'

import { Page, ConsentPageContent, Loading, Button, Icon, FooterLinks, Logo } from '../components'
import { useDseStore, useRouterStore } from '../hooks'

const ConsentPage: React.FC<{}> = () => {
  const { purl, code } = useParams<{ purl: string; code: string }>()

  const CONFIRM_DSE = loader('../graphql/confirmDse.gql')

  const [confirmDse, { loading }] = useMutation(CONFIRM_DSE)

  const dseStore = useDseStore()
  const routerStore = useRouterStore()

  const submit = () => {
    if (!loading && dseStore.doc) {
      if (
        !dseStore.isPersonalDataValid ||
        (dseStore.consent.viaElectronicMail && !dseStore.personalData.eMail) ||
        (dseStore.consent.viaPhone && !dseStore.personalData.phone)
      ) {
        scroll.scrollTo('personalDataForm', {
          duration: 600,
          smooth: true,
        })
      } else {
        confirmDse({
          variables: {
            id: dseStore.id,
            personalData: dseStore.personalData,
            consent: dseStore.consent,
          },
        })
          .then(() => routerStore.push(`/${dseStore.id}/result/success`))
          .catch(() => routerStore.push(`/${dseStore.id}/result/error`))
      }
    }
  }

  if (purl && code) {
    dseStore.setPurl(purl)
    dseStore.setCode(code)
  }

  console.count('[render] Consent Page')

  return useObserver(() => (
    <Page>
      {dseStore.doc ? (
        <Wrapper>
          <Logo />
          <ConsentPageContent />
          <SubmitWrapper>
            <Button onClick={() => submit()}>{loading ? <Icon type="loading" /> : <Icon type="check" />}Bestätigen</Button>
            <FooterLinks />
          </SubmitWrapper>
        </Wrapper>
      ) : (
        <Loading />
      )}
    </Page>
  ))
}

const Wrapper = styled.div`
  .logo {
    margin-bottom: 10px;
  }
`

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 0px;

  .ant-btn {
    ${map({ mobile: '100%', tablet: 'auto' }, (width: string) => `width: ${width};`)}
  }
`

export { ConsentPage }
