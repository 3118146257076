import styled from 'styled-components'
import { Button as AntButton } from 'antd'

const Button = styled(AntButton)`
  height: auto;

  &:not(.ant-btn-link) {
    padding-left: 25px;
    padding-right: 25px;
    height: 40px;

    font-size: 13px;
    letter-spacing: 0.05em;
    border-radius: 2px;
    color: #fff;
    border-color: #1daeee;
    background-color: #1daeee;

    .anticon {
      margin-right: 10px;
    }

    &:not(:disabled):hover {
      background-color: #46bdf0;
      border-color: #46bdf0;
    }
  }

  &.ant-btn-link {
    padding: 0;
  }
`

export { Button }
