import { observable, action } from 'mobx'

export class DseStore {
  /** holds the dseid */
  @observable id: string = ''
  /** holds the dse purl */
  @observable purl: string = ''
  /** holds the dse code */
  @observable code: string = ''
  /** flag whether to fetch pdf */
  @observable fetchPdf = false
  /** flag to delete pdf after download */
  @observable deletePdf = false
  /** holds the flag if the document is loading */
  @observable loading: boolean = false
  /** holds the document */
  @observable doc: DseDocument | undefined

  /** holds the flag if the personal data is valid */
  @observable isPersonalDataValid: boolean = false

  /** holds the personal data */
  @observable personalData: DsePersonalData = {
    salutationTitle: '',
    lastName: '',
    firstName: '',
    additionalAddress: '',
    street: '',
    zipCodeCity: '',
    phone: '',
    phone_private: false,
    phone_business: false,
    eMail: '',
    eMail_private: false,
    eMail_business: false,
  }

  @observable consent: DseConsent = {
    viaPhone: false,
    viaElectronicMail: false,
    mercedesBenzEcosystem: false,
    noMail: false,
    noMercedesBenzEcosystemMail: false,
    revokeMailRevocation: false,
  }

  /**
   * sets the current dseid
   *
   * @param {string} id
   * @memberof DseStore
   */
  @action
  setId(id: string) {
    this.id = id
  }

  /**
   * sets current access purl
   *
   * @param {string} purl
   * @memberof DseStore
   */
  @action
  setPurl(purl: string) {
    this.purl = purl
  }

  /**
   * sets current access code
   *
   * @param {string} code
   * @memberof DseStore
   */
  @action
  setCode(code: string) {
    this.code = code
  }

  /**
   * sets the pending flag
   *
   * @param {boolean} state
   * @memberof DseStore
   */
  @action
  setLoading(state: boolean): void {
    this.loading = state
  }

  /**
   * sets the dse document
   *
   * @param {DseDocument} doc
   * @memberof DseStore
   */
  @action
  setDoc(doc: DseDocument): void {
    this.doc = doc
  }

  /**
   * sets the personal data
   *
   * @param {DsePersonalData} data
   * @memberof DseStore
   */
  @action
  setPersonalData(data: DsePersonalData): void {
    // "hinbiegen" das die PLZ immer 5 Stellig ist (Fehler das 0 wegfiel und es somit 4 stellige PLZs gibt)
    if (data.zipCodeCity) {
      const zipCodeCityGroups: any = /^(\d*) (.*)$/.exec(data.zipCodeCity)

      data.zipCodeCity = `${zipCodeCityGroups[1].padStart(5, '0')} ${zipCodeCityGroups[2]}`
    }

    this.personalData = {
      ...this.personalData,
      ...data,
    }
  }

  /**
   * sets consent
   *
   * @param {DseConsent} data
   * @memberof DseStore
   */
  @action
  setConsent(data: DseConsent): void {
    this.consent = {
      ...this.consent,
      ...data,
    }
  }

  @action
  setFetchPdf(fetchPdf: boolean): void {
    this.fetchPdf = fetchPdf
  }

  @action
  setDeletePdf(shouldDelete: boolean): void {
    this.deletePdf = shouldDelete
  }

  @action
  setPersonalDataValidity(isValid: boolean): void {
    this.isPersonalDataValid = isValid
  }
}
