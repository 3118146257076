import React from 'react'

export interface CompanyAddressProps {
  isGmbH?: boolean
}

const CompanyAddress: React.FC<CompanyAddressProps> = ({ isGmbH }) => (
  <>{isGmbH ? 'Carl-Benz-Straße 1, 06193 Petersberg' : 'Schoemperlenstraße 14, 76185 Karlsruhe'}, Deutschland</>
)

export { CompanyAddress }
