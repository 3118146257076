import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import { FormComponentProps } from 'antd/lib/form/Form'
import { Row, Col, Form } from 'antd'
import { toJS } from 'mobx'
import styled from 'styled-components'

import { Checkbox, Input, InputGroup } from '../'
import { useDseStore } from '../../hooks'

export interface DsePersonalDataFormProps extends FormComponentProps {
  onChange: (values: DsePersonalData) => void
  values?: DsePersonalData
  isMailRequired: boolean
  isPhoneRequired: boolean
}

const { Item } = Form

const DsePersonalDataFormFC = forwardRef<FormComponentProps, DsePersonalDataFormProps>(
  ({ form, onChange, values, isPhoneRequired, isMailRequired }, ref) => {
    const dseStore = useDseStore()

    useImperativeHandle(ref, () => ({
      form,
      onChange,
    }))

    useEffect(() => {
      if (values) {
        const current = form.getFieldsValue()
        const errors = form.getFieldsError()

        if (Object.keys(current).some((key: string) => current[key] !== values[key])) {
          form.setFieldsValue(toJS(values))
        }

        if (errors) {
          if (Object.keys(errors).some((key: string) => errors[key] !== undefined)) {
            dseStore.setPersonalDataValidity(false)
          } else {
            dseStore.setPersonalDataValidity(true)
          }
        }
      }
    }, [values, form, dseStore])

    return (
      <Wrapper>
        {console.count('DsePersonalDataForm')}
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left" colon={false} hideRequiredMark={false}>
          <InputGroup>
            <Row type="flex" gutter={12}>
              <Col span={24}>
                <Item label="Anrede/Titel">{form.getFieldDecorator('salutationTitle')(<Input />)}</Item>
              </Col>
              <Col span={24}>
                <Item label="Nachname">{form.getFieldDecorator('lastName')(<Input />)}</Item>
              </Col>
              <Col span={24}>
                <Item label="Vorname">{form.getFieldDecorator('firstName')(<Input />)}</Item>
              </Col>
              <Col span={24}>
                <Item label="Adresszusatz (z.b Firma)">{form.getFieldDecorator('additionalAddress')(<Input />)}</Item>
              </Col>
              <Col span={24}>
                <Item label="Straße, Haus-Nr.">{form.getFieldDecorator('street')(<Input />)}</Item>
              </Col>
              <Col span={24}>
                <Item label="Plz, Ort, Land">{form.getFieldDecorator('zipCodeCity')(<Input />)}</Item>
              </Col>
              <Col span={24}>
                <Item label="Telefon">
                  {form.getFieldDecorator('phone', {
                    rules: [{ required: isPhoneRequired, message: 'Telefon wird benötigt', whitespace: true }],
                  })(<Input />)}
                  <span className="addition">
                    {form.getFieldDecorator('phone_private', { valuePropName: 'checked' })(<Checkbox>privat</Checkbox>)}
                    {form.getFieldDecorator('phone_business', { valuePropName: 'checked' })(<Checkbox>geschäftlich</Checkbox>)}
                  </span>
                </Item>
              </Col>
              <Col span={24}>
                <Item label="E-Mail">
                  {form.getFieldDecorator('eMail', {
                    rules: [
                      { required: isMailRequired, message: 'E-Mail wird benötigt', whitespace: true },
                      { type: 'email', message: 'Bitte gültige E-Mail eingeben' },
                    ],
                  })(<Input />)}
                  <span className="addition">
                    {form.getFieldDecorator('eMail_private', { valuePropName: 'checked' })(<Checkbox>privat</Checkbox>)}
                    {form.getFieldDecorator('eMail_business', { valuePropName: 'checked' })(<Checkbox>geschäftlich</Checkbox>)}
                  </span>
                </Item>
              </Col>
            </Row>
          </InputGroup>
        </Form>
      </Wrapper>
    )
  }
)

const DsePersonalDataForm = Form.create<DsePersonalDataFormProps>({
  onValuesChange: ({ form, onChange }, changed, all) => {
    onChange(all)
  },
})(DsePersonalDataFormFC)

const Wrapper = styled.div`
  .addition {
    .ant-checkbox-wrapper {
      display: inline-block;

      .ant-checkbox {
        top: 0px;
      }
    }
  }
`

export { DsePersonalDataForm }
