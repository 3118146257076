import { Checkbox as AntCheckbox } from 'antd'
import styled from 'styled-components'

const Checkbox = styled(AntCheckbox)`
  color: #fff;
  display: flex;

  .ant-checkbox {
    top: 3px;
    &-inner {
      background-color: #1e1e1e;
      border-color: #3e3e3f;
    }

    &-checked::after {
      border: none !important;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #1daeee;
  }
`

export { Checkbox }
