import React, { useRef } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useObserver } from 'mobx-react'
import { FormComponentProps } from 'antd/es/form'

import { useDseStore } from '../../hooks'
import { DsePersonalDataForm } from '../DsePersonalDataForm'
import { Tile, DseIndividualConsentForm, RevokeOptOut, Checkbox, CompanyName, CompanyAddress, CompanyTelephone } from '../'

const ConsentPageContent: React.FC = () => {
  const dseStore = useDseStore()

  const formRef = useRef<FormComponentProps>()

  const pDataChange = (values: DsePersonalData) => {
    dseStore.setPersonalData(values)
  }

  const consentDataChange = (values: DseConsent) => {
    dseStore.setConsent(values)

    setTimeout(() => {
      if (formRef.current) {
        formRef.current.form.validateFields(['phone', 'eMail'], { force: true })
      }
    }, 100)
  }

  return useObserver(() => (
    <Wrapper>
      <Row type="flex" gutter={[10, 10]}>
        <Col span={24}>
          <Tile header="Datenschutz-und Einwilligungserklärung für Direktmarketing">
            <p>
              Wir möchten Sie gerne individuell und Ihren Interessen entsprechend informieren und beraten (z.B. über Probefahrten,
              Veranstaltungen, besondere Angebote, Serviceleistungen während eines Händlerbesuchs). Deshalb bitten wir Sie, der Verwendung
              Ihrer Daten für diese Zwecke zuzustimmen. Wir werden diese vertrauensvoll behandeln und sorgsam damit umgehen.
            </p>
          </Tile>
        </Col>
        <Col span={24} id="personalDataForm">
          <Tile>
            <DsePersonalDataForm
              wrappedComponentRef={formRef}
              onChange={pDataChange}
              values={dseStore.personalData}
              isPhoneRequired={dseStore.consent.viaPhone || false}
              isMailRequired={dseStore.consent.viaElectronicMail || false}
            />
          </Tile>
        </Col>
        <Col xs={24} md={12}>
          <Tile className="blue" header="Individuelle Einwilligung">
            <p>
              Ja, ich möchte von der <CompanyName isGmbH={dseStore.doc?.isGmbH} />, <CompanyAddress isGmbH={dseStore.doc?.isGmbH} />{' '}
              individuell und meinen Interessen entsprechend (Profilbildung) über deren Produkte und Dienstleistungen sowie die der{' '}
              {dseStore.doc?.isTruck ? <>Daimler Truck AG</> : <>Mercedes-Benz Group AG</>} und ihrer verbundenen Unternehmen wie folgt
              informiert und beraten werden:
            </p>
            <DseIndividualConsentForm onChange={consentDataChange} values={dseStore.consent} />
            <p>
              Durch Ankreuzen dieser Kästchen willige ich in die Verarbeitung meiner personenbezogenen Daten zu den vorstehenden Zwecken
              ein.
            </p>
          </Tile>
        </Col>
        <Col xs={24} md={12}>
          <Tile header="Genutzte personenbezogene Daten">
            <ul>
              <li>
                Mein Name und meine Kontaktdaten (z.B. Anschrift, E-Mail-Adresse, Telefonnummer, Messenger ID, Adresse in sozialen
                Netzwerken),
              </li>
              {dseStore.doc?.isTruck ? (
                <li>Details zu meiner Person (z.B. Hobbys, Interessen),</li>
              ) : (
                <li>Details zu meiner Person (z.B. Hobbys, Interessen, Anzahl an Personen, die in meinem Haushalt leben),</li>
              )}
              <li>Daten zu meinem Fahrzeug (z.B. Fahrzeugkonfiguration, Kilometerstand, Daten aus dem digitalen Serviceheft), und</li>
              {dseStore.doc?.isTruck ? (
                <li>
                  Informationen über gekaufte Produkte und in Anspruch genommene Services (z.B. Leasingverträge, gebuchte Telematik Dienste,
                  gespeicherte Fahrzeugkonfigurationen)
                </li>
              ) : (
                <li>
                  Informationen über gekaufte Produkte und in Anspruchgenommene Services (z.B. Leasingverträge, gebuchte Mercedes me
                  Dienste, gespeicherte Fahrzeugkonfigurationen)
                </li>
              )}
            </ul>
          </Tile>
        </Col>
        <Col xs={24} md={12}>
          {dseStore.doc?.isTruck ? (
            <Tile className="blue" header="Daimler Truck Ökosystem Einwilligung">
              <Checkbox
                checked={dseStore.consent.mercedesBenzEcosystem}
                onChange={(e: CheckboxChangeEvent) => {
                  consentDataChange({ mercedesBenzEcosystem: e.target.checked })
                }}
              >
                <div>
                  Ja, ich möchte unabhängig von der Individuellen Einwilligung über die oben von mir gewählten Formen der Ansprache auch von
                  den Daimler Truck Ökosystem Gesellschaften individuell und meinen Interessen entsprechend (Profilbildung) über Produkte
                  und Dienstleistungen der Daimler Truck AG und deren verbundene Unternehmen, einschließlich der Daimler Truck Ökosystem
                  Gesellschaften informiert und beraten werden.
                </div>
              </Checkbox>
              <p>
                Durch Ankreuzen dieses Kästchens willige ich in die Verarbeitung und die Weitergabe meiner personenbezogenen Daten von und
                unter den Daimler Truck Ökosystem Gesellschaften, jeder als einzelner Verantwortlicher, für die vorstehenden Zwecke ein.
              </p>
            </Tile>
          ) : (
            <Tile className="blue" header="Mercedes-Benz Ökosystem Einwilligung">
              <Checkbox
                checked={dseStore.consent.mercedesBenzEcosystem}
                onChange={(e: CheckboxChangeEvent) => {
                  consentDataChange({ mercedesBenzEcosystem: e.target.checked })
                }}
              >
                <div>
                  Ja, ich möchte unabhängig von der Individuellen Einwilligung über die oben von mir gewählten Formen der Ansprache auch von
                  den Mercedes-Benz Ökosystem Gesellschaften individuell und meinen Interessen entsprechend (Profilbildung) über Produkte
                  und Dienstleistungen der Mercedes-Benz Group AG und deren verbundene Unternehmen, einschließlich der Mercedes-Benz
                  Ökosystem Gesellschaften informiert und beraten werden.
                </div>
              </Checkbox>
              <p>
                Durch Ankreuzen dieses Kästchens willige ich in die Verarbeitung und die Weitergabe meiner personenbezogenen Daten von und
                unter den Mercedes-Benz Ökosystem Gesellschaften, jeder als einzelner Verantwortlicher, für die vorstehenden Zwecke ein.
              </p>
            </Tile>
          )}
        </Col>
        <Col xs={24} md={12}>
          {dseStore.doc?.isTruck ? (
            <Tile header="Daimler Truck Ökosystem Gesellschaften">
              <ul>
                <li>
                  Daimler Truck AG einschließlich ihres autorisierten Händlers und autorisierten Servicepartners, die örtlich am nächsten zu
                  der von mir angegebenen Adresse gelegen sind, der autorisierte Händler und autorisierte Servicepartner der Daimler Truck
                  AG, mit denen ich in Kontakt getreten bin oder die ich als bevorzugte Händler oder bevorzugte Servicepartner ausgewählt
                  habe,
                </li>
                <li>Daimer Truck Financial Services GmbH,</li>
                <li>Daimer Truck Financial Services Deutschland GmbH,</li>
                <li>Fleetboard Logistics GmbH,</li>
                <li>Mercedes ServiceCard GmbH & Co. KG und deren Rechtsnachfolger.</li>
              </ul>
            </Tile>
          ) : (
            <Tile header="Mercedes-Benz Ökosystem Gesellschaften">
              <ul>
                <li>
                  Mercedes-Benz AG einschließlich ihres autorisierten Händlers und autorisierten Servicepartners, die örtlich am nächsten zu
                  der von mir angegebenen Adresse gelegen sind, der autorisierte Händler und autorisierte Servicepartner der Mercedes-Benz
                  AG, mit denen ich in Kontakt getreten bin oder die ich als bevorzugte Händler oder bevorzugte Servicepartner ausgewählt
                  habe,
                </li>
                <li>Mercedes-Benz Bank AG,</li>
                <li>Mercedes-Benz Leasing GmbH,</li>
                <li>Mercedes-Benz Versicherung AG,</li>
                <li>Mercedes-Benz Automotive Mobility AG,</li>
                <li>Mercedes-Benz Connectivity Services GmbH,</li>
                <li>Mercedes-Benz Leasing Deutschland GmbH,</li>
                <li>Mercedes-Benz Mobility AG,</li>
                <li>Mercedes pay GmbH und deren Rechtsnachfolger.</li>
              </ul>
            </Tile>
          )}
        </Col>
        <Col xs={24} md={12}>
          <Tile header="Freiwilligkeit und Widerspruchsrecht">
            <p>
              Die Erklärung Ihrer Einwilligung(en) ist freiwillig. Sie können Ihre Einwilligung(en) jederzeit widerrufen oder der
              Verarbeitung Ihrer personenbezogenen Daten zu Direktmarketingzwecken jederzeit widersprechen. Soweit Sie Ihre Einwilligung
              nicht erklären oder sie widerrufen, können wir und/oder die {dseStore.doc?.isTruck ? <>Daimler Truck</> : <>Mercedes-Benz</>}{' '}
              Ökosystem Gesellschaften Sie nicht mit Informationen und Beratung zu unseren Produkten und Dienstleistungen kontaktieren.
            </p>
          </Tile>
        </Col>

        <Col xs={24} md={12}>
          <Tile className="darkblue" header="Postalischer Versand">
            <p>
              Unabhängig von der Einwilligung werden wir und/oder die {dseStore.doc?.isTruck ? <>Daimler Truck</> : <>Mercedes-Benz</>}{' '}
              Ökosystem Gesellschaften Ihre personenbezogenen Daten jeweils auch nutzen, um Sie mit Informationen und Beratung über unsere
              Produkte und Dienstleistungen per Post zu kontaktieren. Wenn Sie solche Informationen per Post nicht mehr erhalten möchten,
              wählen Sie bitte die untenstehenden Kästchen aus:
            </p>
            <Checkbox
              checked={dseStore.consent.noMail}
              onChange={(e: CheckboxChangeEvent) => consentDataChange({ noMail: e.target.checked })}
            >
              <div>
                Nein, ich möchte keine Informationen und Beratung (mehr) per Post von der <CompanyName isGmbH={dseStore.doc?.isGmbH} />{' '}
                <CompanyAddress isGmbH={dseStore.doc?.isGmbH} /> erhalten.
              </div>
            </Checkbox>
            <Checkbox
              checked={dseStore.consent.noMercedesBenzEcosystemMail}
              onChange={(e: CheckboxChangeEvent) => consentDataChange({ noMercedesBenzEcosystemMail: e.target.checked })}
            >
              <div>
                Nein, ich möchte keine Informationen und Beratung (mehr) per Post von allen anderen{' '}
                {dseStore.doc?.isTruck ? <>Daimler Truck</> : <>Mercedes-Benz</>} Ökosystem Gesellschaften erhalten.
              </div>
            </Checkbox>
          </Tile>
        </Col>
        <Col xs={24} md={12}>
          <Row gutter={[0, 10]}>
            <Col>
              <Tile header="Profilbildung">
                <p>
                  Um Ihnen die vorstehenden individualisierten und Ihren Interessen entsprechenden Informationen und Beratungen schriftlich,
                  persönlich oder über den von Ihnen gewählten Kontaktkanal unterbreiten zu können, können Analyseverfahren einschließlich
                  Profilbildung verwendet werden.
                </p>
              </Tile>
            </Col>

            <Col>
              <Tile header="Keine Wirkung für andere Einwilligungen">
                <p>
                  Ob Sie Ihre Individuelle Einwilligung und/oder die {dseStore.doc?.isTruck ? <>Daimler Truck</> : <>Mercedes-Benz</>}{' '}
                  Ökosystem Einwilligung erteilt haben oder nicht, hat keine Auswirkung auf eine andere Individuelle Einwilligung, die Sie
                  in der Vergangenheit gegeben haben oder zukünftig geben werden. Diese bleibt bis zum Widerruf wirksam. Wenn Sie diese
                  andere Individuelle Einwilligung widerrufen wollen oder der Verarbeitung Ihrer personenbezogenen Daten für
                  Direktmarketingzwecke durch eine einzelne Gesellschaft widersprechen wollen, kontaktieren Sie bitte die jeweilige
                  Gesellschaft direkt.
                </p>
              </Tile>
            </Col>

            <Col>
              <Tile header="Rechtsgrundlage">
                <p>
                  Die Verarbeitung Ihrer personenbezogenen Daten für die vorgenannten Zwecke erfolgt auf Grundlage Ihrer Einwilligung(en)
                  nach Art. 6 (1) (a) der Datenschutz-Grundverordnung („DS-GVO"). Die Verarbeitung zum Zwecke des postalischen Versands und
                  der Sicherstellung, dass Sie nur nach Ihren Maßgaben kontaktiert werden, beruht auf einer Interessenabwägung und damit auf
                  berechtigten Interessen gemäß Art. 6 (1) (f) DS-GVO. Die berechtigten Interessen bestehen darin, Sie individuell und Ihren
                  Interessen entsprechend informieren und beraten zu können und dass Sie keine Informationen erhalten, die für Sie nicht
                  relevant sind. Mehr Informationen über die Interessenabwägung erhalten Sie auf Anfrage. Zusätzlich zu Vorstehendem können
                  Ihre personenbezogenen Daten auch auf anderen Rechtsgrundlagen verarbeitet werden.
                  <br /> <br />
                  Soweit dies der Fall ist, werden Sie hierüber entsprechend informiert.
                </p>
              </Tile>
            </Col>
            <Col>
              <Tile header="Speicherdauer">
                <p style={{ marginBottom: '1.15em' }}>
                  Ihre personenbezogenen Daten werden von dem jeweiligen Verantwortlichen nicht länger gespeichert als es erforderlich ist,
                  um die vorgenannten Zwecke zu erreichen, und maximal für einen Zeitraum von fünf Jahren ab dem Zeitpunkt des letzten
                  Kontakts mit dem jeweiligen Verantwortlichen, oder für einen längeren Zeitraum, soweit dies aufgrund von
                  Aufbewahrungspflichten erforderlich ist, die für den jeweiligen Verantwortlichen gelten.
                </p>
              </Tile>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12}>
          <Row gutter={[0, 10]}>
            <Col>
              <Tile header="Datenschutzbeauftragte und Datenschutzkontakte">
                {dseStore.doc?.isTruck ? (
                  <p>
                    Den Datenschutzbeauftragten ("DSB") erreichen Sie wie folgt (wenn mit einem „*" gekennzeichnet, wurde kein DSB benannt):
                    Für (0) <CompanyName isGmbH={dseStore.doc?.isGmbH} /> <CompanyAddress isGmbH={dseStore.doc?.isGmbH} />: Herr Thomas Nees
                    / Datenschutzbeauftragter, <CompanyAddress isGmbH={dseStore.doc?.isGmbH} />, datenschutz@sug.de; für (1) Daimler Truck
                    AG, (2) Daimler Truck Financial Servieces GmbH, (3) Daimler Truck Financial Servieces Deutschland GmbH, (4) Fleetboard
                    Logistics GmbH, (5) Mercedes ServiceCard GmbH & Co. KG: Konzernbeauftragte, HPC DTF3B, 70745 Leinfelden-Echterdingen,
                    Deutschland, dataprivacy@daimlertruck.com.
                  </p>
                ) : (
                  <p>
                    Den Datenschutzbeauftragten ("DSB") erreichen Sie wie folgt (wenn mit einem „*" gekennzeichnet, wurde kein DSB benannt):
                    Für (0) <CompanyName isGmbH={dseStore.doc?.isGmbH} /> <CompanyAddress isGmbH={dseStore.doc?.isGmbH} />: Herr Thomas Nees
                    / Datenschutzbeauftragter, <CompanyAddress isGmbH={dseStore.doc?.isGmbH} />, datenschutz@sug.de; für (1) Mercedes-Benz
                    AG, (2) Mercedes-Benz Bank AG, (3) Mercedes-Benz Leasing GmbH, (4) Mercedes-Benz Versicherung AG, (5) Mercedes-Benz
                    Automotive Mobility GmbH, (6) Mercedes-Benz Connectivity Services GmbH, (7) Mercedes-Benz Leasing Deutschland GmbH, (8)
                    Mercedes-Benz Mobility AG, (9) Mercedes pay GmbH: Konzernbeauftragter für den Datenschutz, HPC E600, 70546 Stuttgart,
                    Deutschland, data.protection@daimler.com.
                  </p>
                )}
              </Tile>
            </Col>
            <Col>
              <Tile header="Ihre Rechte">
                <p>
                  Wenn Sie Ihre Einwilligung zu einer Verarbeitungstätigkeit erteilt haben, können Sie diese Einwilligung jederzeit mit
                  Wirkung für die Zukunft widerrufen. Durch einen solchen Widerruf wird die Rechtmäßigkeit der aufgrund der Einwilligung bis
                  zum Widerruf erfolgten Verarbeitung nicht berührt. Nach den anwendbaren Datenschutzgesetzen können Ihnen folgende Rechte
                  zustehen: das Recht auf Auskunft über Ihre personenbezogenen Daten, das Recht auf Berichtigung Ihrer personenbezogenen
                  Daten, das Recht auf Löschung Ihrer personenbezogenen Daten, das Recht auf Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten, das Recht auf Datenübertragbarkeit und das Recht, der Verarbeitung Ihrer personenbezogenen Daten
                  zu widersprechen. Bitte beachten Sie, dass die vorgenannten Rechte durch nationales Recht eingeschränkt sein können. Sie
                  haben zudem das Recht, eine Beschwerde an die zuständige Aufsichtsbehörde zu richten. Zur Ausübung Ihrer Rechte können Sie
                  sich jederzeit an uns wenden:
                </p>
                <p>
                  <CompanyName isGmbH={dseStore.doc?.isGmbH} />
                  <br />
                  <CompanyAddress isGmbH={dseStore.doc?.isGmbH} />
                  <br />
                  datenschutz@sug.de
                  <br />
                  <CompanyTelephone isGmbH={dseStore.doc?.isGmbH} />
                </p>
                <p>
                  {dseStore.doc?.isTruck ? <>Daimler Truck AG</> : <>Mercedes-Benz Group AG</>}
                  <br />
                  {dseStore.doc?.isTruck ? <>HPC V422, 10878 Berlin, Deutschland</> : <>HPC V421, 10878 Berlin, Deutschland</>}
                  <br />
                  {dseStore.doc?.isTruck ? <>kundenrechte_dt@daimler.com</> : <>kundenrechte_mb@daimler.com</>}
                  <br />
                  +49 800/623-2229
                </p>
                <p>
                  Unter gewissen Umständen haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, der
                  Verarbeitung der Sie betreffenden personenbezogenen Daten zu widersprechen und wir können verpflichtet sein, Ihre Daten
                  nicht länger zu verarbeiten. Sie haben zusätzlich das Recht auf Widerspruch in Bezug auf die Verarbeitung Ihrer
                  personenbezogenen Daten zu Direktmarketingzwecken bzw. in Bezug auf die Verarbeitung einschließlich der Profilbildung,
                  soweit diese im Zusammenhang mit Direktmarketing steht.
                </p>
              </Tile>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row type="flex" style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Col span={24}>
          <Tile header="Andere Empfänger">
            <p>
              Dieses Dokument wird an die {dseStore.doc?.isTruck ? <>Daimler Truck AG</> : <>Mercedes-Benz Group AG</>} weitergegeben. Diese
              Weitergabe erfolgt gemeinsam mit Ihren Kontaktdaten, die für eine Zuordnung zu Ihnen erforderlich sind. Die Unternehmen, die
              auf der Grundlage dieser Einwilligungserklärung berechtigt sind, Sie zu kontaktieren, können auf diese Einwilligungserklärung
              zugreifen, um sicherzustellen, dass Sie nur nach Ihren Maßgaben kontaktiert werden. Zusätzlich kann jeder Verantwortliche
              externe Auftragsverarbeiter wie IT-Dienstleister oder Marketingagenturen einsetzen.
            </p>
          </Tile>
        </Col>
      </Row>

      {dseStore.doc?.mailRevoked ? (
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Tile className="blue" header="Erklärung zum Erhalt von Postwerbung">
              <RevokeOptOut />
            </Tile>
          </Col>
        </Row>
      ) : null}
    </Wrapper>
  ))
}

const Wrapper = styled.div`
  > .tile {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tile {
    .header {
      font-size: 2vh;
      border-bottom-color: #fff;
    }

    &.blue {
      background-color: #059dff;
    }

    &.darkblue {
      background-color: #00263f;
    }
  }

  > .ant-row-flex {
    > .ant-col {
      &:first-child {
        .tile .header {
          font-size: 2.5vh;
        }
      }
    }

    .ant-col > .tile {
      height: 100%;
    }
  }

  .individualConsent {
    margin-bottom: 14px;
  }

  ul {
    list-style-type: decimal;
    padding-left: 20px;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }

  .ant-form-explain {
    color: rgb(245, 34, 45);
  }
`

export { ConsentPageContent }
