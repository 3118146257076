import React from 'react'
import styled from 'styled-components'

import { Icon } from '../'

const Loading: React.FC = () => {
  return (
    <Wrapper>
      <Icon type="loading" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-size: 42px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export { Loading }
