import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { useRouterStore } from '../../hooks'

import { Page, Result, Tile, Button } from '../../components'

const UploadResultPage: React.FC<{}> = () => {
  const { result } = useParams<{ result: DseResult }>()
  const routerStore = useRouterStore()

  return (
    <>
      {console.count('[render] Upload Page')}
      <Page>
        <Wrapper>
          <Tile>
            <Result
              title={result === 'success' ? 'Erfolgreich hochgeladen' : 'Ein Fehler ist aufgetreten'}
              status={result === 'success' ? 'success' : 'error'}
              extra={
                <Button type="primary" onClick={() => routerStore.push('/upload')}>
                  Zurück
                </Button>
              }
            />
          </Tile>
        </Wrapper>
      </Page>
    </>
  )
}

const Wrapper = styled.div`
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: calc(100% - 20px);

  .tile {
    padding: 0px;
  }

  .ant-result-title {
    color: #fff;
  }
`

export { UploadResultPage }
