import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import { FormComponentProps } from 'antd/lib/form/Form'
import { Row, Col, Form } from 'antd'
import { toJS } from 'mobx'

import { Checkbox, InputGroup } from '../'

export interface DseIndividualConsentFormProps extends FormComponentProps {
  onChange: (values: DseConsent) => void
  values?: DseConsent
}

const DseIndividualConsentFormFC = forwardRef<FormComponentProps, DseIndividualConsentFormProps>(({ form, onChange, values }, ref) => {
  useImperativeHandle(ref, () => ({
    form,
    onChange,
  }))

  useEffect(() => {
    if (values) {
      const current = form.getFieldsValue()

      if (Object.keys(current).some((key: string) => current[key] !== values[key])) {
        form.setFieldsValue(toJS(values))
      }
    }
  }, [values, form])

  return (
    <>
      {console.count('IndividualConsentForm')}
      <Form className="individualConsent" colon={false}>
        <InputGroup>
          <Row type="flex" gutter={12}>
            <Col span={24}>
              {form.getFieldDecorator('viaPhone', { valuePropName: 'checked' })(
                <Checkbox>
                  <div>per Telefon (persönliche Anrufe)</div>
                </Checkbox>
              )}
            </Col>
            <Col span={24}>
              {form.getFieldDecorator('viaElectronicMail', { valuePropName: 'checked' })(
                <Checkbox>
                  <div>per elektronischer Post (insb. E-Mail, SMS, Messenger)</div>
                </Checkbox>
              )}
            </Col>
          </Row>
        </InputGroup>
      </Form>
    </>
  )
})

const DseIndividualConsentForm = Form.create<DseIndividualConsentFormProps>({
  onValuesChange: ({ form, onChange }, changed, all) => {
    onChange(all)
  },
})(DseIndividualConsentFormFC)

export { DseIndividualConsentForm }
