import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { Page, Input, Tile, Logo, DseCodeForm, DseCodeFormData } from '../components'
import { useRouterStore } from '../hooks'

const CodePage: React.FC<{}> = () => {
  const routerStore = useRouterStore()
  const { purl } = useParams<{ purl: string }>()

  const submit = (e: DseCodeFormData) => {
    routerStore.push(`/${purl}/${e.code}/`)
  }

  return (
    <>
      {console.count('[render] Code Page')}
      <Page>
        <Wrapper>
          <Logo />
          <Tile header="S&G DSE">
            <DseCodeForm onSubmit={submit} />
          </Tile>
        </Wrapper>
      </Page>
    </>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: calc(100% - 20px);

  ${Input} {
    width: 100%;
  }

  .logo {
    text-align: center;
    margin-bottom: 10px;
  }

  .ant-upload {
    .ant-btn {
      background-color: #333232;
      border-color: #333232;

      &:hover {
        background-color: #6c6969;
        border-color: #6c6969;
      }
    }
  }
`

export { CodePage }
