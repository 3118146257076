import styled from 'styled-components'
import { Input as AntInput } from 'antd'

const AntInputGroup = AntInput.Group

const Input = styled(AntInput)`
  border-radius: 0px;
  border-color: #575958;
  color: #fff;
  height: 40px;
  box-shadow: none !important;
  transition: all 300ms;
  background-color: #1d1e1e !important;
  color: #fff !important;

  &:focus,
  &:hover {
    border-color: #6c6969;
  }

  &-suffix,
  &-prefix {
    color: #575958;
    font-size: 18px;
  }
`

const InputGroup = styled(AntInputGroup)``

export { Input, InputGroup }
