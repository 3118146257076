import React from 'react'
import { reaction, toJS } from 'mobx'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'

import { useDseStore } from '../../hooks'

const GET_DSE = loader('../../graphql/getDse.gql')

const DseHandler: React.FC<{}> = () => {
  const dseStore = useDseStore()

  const { data, loading, refetch } = useQuery<{ dse: DseDocument }>(GET_DSE, {
    variables: {
      id: dseStore.id,
      purl: dseStore.purl,
      code: dseStore.code,
      pdf: dseStore.fetchPdf,
      deleteFlag: dseStore.deletePdf,
    },
  })

  dseStore.setLoading(true)

  if (data) {
    if (data.dse) {
      dseStore.setId(data.dse._id)
      dseStore.setDoc(data.dse)
      if (data.dse.personalData) {
        dseStore.setPersonalData(data.dse.personalData)
      }
    }
    dseStore.setLoading(false)
  }

  reaction(
    () => toJS(dseStore.purl),
    () => {
      if (!loading && dseStore.purl) {
        refetch({
          purl: dseStore.purl,
          code: dseStore.code,
          pdf: dseStore.fetchPdf,
        })
      }
    }
  )

  return null
}

export { DseHandler }
