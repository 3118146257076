import React from 'react'
import styled from 'styled-components'

const FooterLinks: React.FC = () => {
  return (
    <Wrapper>
      <a href="https://www.sug.de/impressum" target="_blank" rel="noopener noreferrer">
        Impressum
      </a>
      {' | '}
      <a href="https://www.sug.de/datenschutz" target="_blank" rel="noopener noreferrer">
        Datenschutz
      </a>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  line-height: 40px;

  a {
    color: #1daeee;
  }
`

export { FooterLinks }
