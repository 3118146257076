import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { useObserver } from 'mobx-react'

import { Page, Result, Tile, Button } from '../components'
import { useDseStore } from '../hooks'
import { download } from '../helpers/download'

const ConsentResultPage: React.FC<{}> = () => {
  const { result, id } = useParams<{ id: string; result: 'success' | 'error' }>()
  const dseStore = useDseStore()
  if (id) {
    dseStore.setFetchPdf(true)
    dseStore.setDeletePdf(false)
    dseStore.setId('')
    dseStore.setId(id)
  }

  return useObserver(() => (
    <>
      {console.count('[render] Upload Result Page')}
      <Page>
        <Wrapper>
          <Tile>
            {result === 'success' ? (
              <Result
                title="Daten erfolgreich bestätigt"
                status="success"
                extra={
                  <Button id="pdf" onClick={() => dseStore.doc && download(dseStore.doc.pdf, dseStore.id)}>
                    Dokument herunterladen
                  </Button>
                }
              />
            ) : (
              <Result title="Es ist ein Fehler aufgetreten" status="error" />
            )}
          </Tile>
        </Wrapper>
      </Page>
    </>
  ))
}

const Wrapper = styled.div`
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .tile {
    padding: 0px;
    min-width: 500px;
  }

  .ant-result-title {
    color: #fff;
  }
`

export { ConsentResultPage }
