export const download = (pdf: string, id: string) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var byteCharacters = atob(pdf)
    var byteNumbers = new Array(byteCharacters.length)
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    var byteArray = new Uint8Array(byteNumbers)
    var blob = new Blob([byteArray], {
      type: 'application/pdf',
    })
    window.navigator.msSaveOrOpenBlob(blob, `${id}.pdf`)
  } else {
    const a = document.createElement('a')
    a.href = `data:application/pdf;base64,${pdf}`
    a.download = `${id}.pdf`
    a.click()
  }
}
