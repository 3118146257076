import React from 'react'
import styled from 'styled-components'

const Logo: React.FC = () => {
  return (
    <Wrapper className="logo">
      <img src="/sug-logo.svg" alt="logo" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-block;

  img {
    width: auto;
    height: 40px;
  }
`

export { Logo }
