import React from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'

import { Page, DseUploadForm, Input, Tile, Logo } from '../../components'
import { useRouterStore } from '../../hooks'

const UploadPage: React.FC<{}> = () => {
  const CREATE_DSE = loader('../../graphql/createDse.gql')
  const [addDse, { loading }] = useMutation(CREATE_DSE)

  const routerStore = useRouterStore()

  const submit = (variables: any) => {
    addDse({ variables: variables })
      .then(() => navigate('/upload/success'))
      .catch(() => navigate('/upload/error'))
  }

  const navigate = (url: string) => {
    routerStore.push(url)
  }

  return (
    <>
      {console.count('[render] Upload Page')}
      <Page>
        <Wrapper>
          <Logo />
          <Tile header="S&G DSE - Upload">
            <DseUploadForm loading={loading} onSubmit={submit} />
          </Tile>
        </Wrapper>
      </Page>
    </>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: calc(100% - 20px);

  ${Input} {
    width: 100%;
  }

  .logo {
    text-align: center;
    margin-bottom: 10px;
  }

  .ant-upload {
    .ant-btn {
      background-color: #333232;
      border-color: #333232;

      &:hover {
        background-color: #6c6969;
        border-color: #6c6969;
      }
    }
  }
`

export { UploadPage }
