import React from 'react'
import styled from 'styled-components'

export interface TileProps {
  header?: string | React.ReactNode
  className?: string
}

const Tile: React.FC<TileProps> = ({ header, children, className }) => {
  return (
    <Wrapper className={`tile ${className ? className : ''}`}>
      {header ? <div className="header">{header}</div> : null}
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px 35px;
  background-color: #252525;
  color: #fff;
  border-radius: 5px;

  .header {
    font-family: 'Roboto';
    font-size: 2vh;
    margin-bottom: 20px;
    border-bottom: 1px solid #3d3d3d;
  }
`

export { Tile }
