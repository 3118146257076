import React from 'react'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { Checkbox } from '../'
import { useDseStore } from '../../hooks'

const RevokeOptOut: React.FC = () => {
  const dseStore = useDseStore()

  return (
    <>
      <p>
        Wir würden Sie gerne wie von Ihnen gewünscht per Post informieren. In der Vergangenheit hatten Sie uns mitgeteilt, dass Sie nicht
        auf dem Postweg kontaktiert werden möchten. Um Sie zukünftig auf dem Postweg informieren zu können, benötigen wir die nachfolgende
        Erklärung:
      </p>
      <p>
        <Checkbox onChange={(e: CheckboxChangeEvent) => dseStore.setConsent({ revokeMailRevocation: e.target.checked })}>
          Ich bestätige, dass der von mir in der Vergangenheit erklärte Widerspruch zum Erhalt von Werbung auf dem Postweg nicht mehr gelten
          soll (Widerruf meines Opt-Outs) und mir zukünftig Post zu Werbezwecken zugesandt werden darf. Dies gilt solange, bis ich wieder
          einen Widerspruch erteile.
        </Checkbox>
      </p>
      <p>Einen erneuten Widerspruch können Sie jederzeit und kostenlos wie in der Einwilligungserklärung beschrieben erklären.</p>
    </>
  )
}

export { RevokeOptOut }
