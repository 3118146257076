import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from 'react-apollo'
import ApolloClient, { InMemoryCache } from 'apollo-boost-upload'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import { Provider } from 'mobx-react'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'

import * as serviceWorker from './serviceWorker'
import App from './App'
import { DseStore } from './stores'

const rootElement = document.getElementById('root')

const stores = {
  routerStore: new RouterStore(),
  dseStore: new DseStore(),
}

const browserHistory = createBrowserHistory()
const synchronizedHistory = syncHistoryWithStore(browserHistory, stores.routerStore)

const client = new ApolloClient({
  uri: 'https://api.sug-dse.de/',
  // uri: 'http://127.0.0.1:4000/',
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider {...stores}>
      <Router history={synchronizedHistory}>
        <App />
      </Router>
    </Provider>
  </ApolloProvider>,
  rootElement
)

serviceWorker.unregister()
