import React from 'react'
import { useParams } from 'react-router'
import { useObserver } from 'mobx-react'
import styled from 'styled-components'

import { Page, Loading, Button, Tile } from '../components'
import { useDseStore } from '../hooks'
import { download } from '../helpers/download'

const DownloadPage: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>()

  const dseStore = useDseStore()

  if (id) {
    dseStore.setFetchPdf(true)
    dseStore.setDeletePdf(true)
    dseStore.setId(id)
  }

  return useObserver(() => (
    <Page>
      {dseStore.doc ? (
        <Wrapper>
          <Tile>
            <Button id="pdf" onClick={() => dseStore.doc && download(dseStore.doc.pdf, dseStore.id)}>
              Download
            </Button>
            {download(dseStore.doc.pdf, dseStore.id)}
          </Tile>
        </Wrapper>
      ) : (
        <Loading />
      )}
    </Page>
  ))
}

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export { DownloadPage }
