import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000;
    color: #fff;
  }

  body {
    font-family: 'Rubik', sans-serif;
  }

  h1, h2, h3, h4, h5 {
    color: #fff;
  }

  .ant-form {
    &-item-label {
      > label {
        color: #fff;
      }
    }
  }
`

export { GlobalStyles }
