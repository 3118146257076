import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import 'antd/dist/antd.css'

import { GlobalStyles } from './components/GlobalStyles'
import { UploadPage, UploadResultPage, ConsentPage, ConsentResultPage, DownloadPage, CodePage } from './pages'
import { DseHandler } from './components/DseHandler'

const App: React.FC<{}> = () => (
  <>
    <GlobalStyles />
    <DseHandler />
    <Switch>
      <Route path="/upload" exact component={UploadPage} />
      <Route path="/upload/:result" exact component={UploadResultPage} />

      <Route path="/:id/download" exact component={DownloadPage} />
      <Route path="/:id/result/:result" exact component={ConsentResultPage} />
      <Route path="/:purl/" exact component={CodePage} />
      <Route path="/:purl/:code/" exact component={ConsentPage} />

      <Redirect to="/upload" />
    </Switch>
  </>
)

export default App
