import React, { forwardRef, useImperativeHandle } from 'react'
import { FormComponentProps } from 'antd/lib/form/Form'
import { Row, Col, Form } from 'antd'
import styled from 'styled-components'

import { InputGroup, Button, Input } from './'

export interface DseCodeFormData {
  code: string
}

interface DseCodeFormProps extends FormComponentProps {
  onChange?: (values: DseCodeFormData) => void
  loading?: boolean
  onSubmit: (values: DseCodeFormData) => void
}

const DseCodeFormFC = forwardRef<FormComponentProps, DseCodeFormProps>(({ form, onChange, onSubmit, loading }, ref) => {
  useImperativeHandle(ref, () => ({
    form,
    onSubmit,
    onChange,
  }))

  const isValid = () => {
    return !Object.values(form.getFieldsError()).some((e) => e !== undefined)
  }

  return (
    <Wrapper>
      {console.count('DseCodeForm')}
      <Form colon={false}>
        <InputGroup>
          <Row>
            <Col span={24}>
              <Form.Item label="Geben Sie ihren Code Ein">
                {form.getFieldDecorator('code', {
                  rules: [{ required: true, message: 'Code wird benötigt' }],
                })(<Input placeholder="Code eingeben" />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button
                  disabled={!form.isFieldsTouched() || !isValid()}
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    if (isValid()) {
                      const { code } = form.getFieldsValue()
                      onSubmit({ code })
                    }
                  }}
                >
                  Absenden
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>
      </Form>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  label {
    color: #fff;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  button[type='submit'] {
    margin-top: 20px;
  }
`

const DseCodeForm = Form.create<DseCodeFormProps>({
  onValuesChange: ({ form, onChange }, changed, all) => {
    onChange && onChange(all)
  },
})(DseCodeFormFC)

export { DseCodeForm }
