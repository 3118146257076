import React, { forwardRef, useImperativeHandle } from 'react'
import { FormComponentProps } from 'antd/lib/form/Form'
import { Row, Col, Form } from 'antd'
import styled from 'styled-components'

import { InputGroup, Upload, Icon, Button, Input } from '../'

export interface DseUploadFormProps extends FormComponentProps {
  onChange?: (values: DseUpload) => void
  loading?: boolean
  onSubmit: (values: DseUpload) => void
}

const DseUploadFormFC = forwardRef<FormComponentProps, DseUploadFormProps>(({ form, onChange, onSubmit, loading }, ref) => {
  useImperativeHandle(ref, () => ({
    form,
    onSubmit,
    onChange,
  }))

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }

    if (e.fileList.length > 1) {
      e.fileList.shift()
    }

    return e && e.fileList
  }

  const isValid = () => {
    return !Object.values(form.getFieldsError()).some(e => e !== undefined)
  }

  return (
    <Wrapper>
      {console.count('DseUploadForm')}
      <Form colon={false}>
        <InputGroup>
          <Row>
            <Col span={24}>
              <Form.Item label="Kunden E-Mail-Adresse">
                {form.getFieldDecorator('email', {
                  rules: [
                    { required: true, message: 'E-Mail wird benötigt' },
                    { type: 'email', message: 'Bitte gültige E-Mail eingeben' },
                  ],
                })(<Input placeholder="E-Mail eingeben" />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Kunden DSE aus CCS als PDF">
                {form.getFieldDecorator('file', {
                  valuePropName: 'fileList',
                  getValueFromEvent: normFile,
                  rules: [{ required: true, message: 'PDF wird benötigt' }],
                })(
                  <Upload showUploadList={false} accept=".pdf" beforeUpload={() => false}>
                    <Button>{form.getFieldValue('file') ? <Icon type="check" /> : <Icon type="upload" />} Datei auswählen…</Button>
                  </Upload>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button
                  disabled={!form.isFieldsTouched() || !isValid()}
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    if (isValid()) {
                      const { email, file } = form.getFieldsValue()
                      onSubmit({ email, file })
                    }
                  }}
                >
                  Absenden
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </InputGroup>
      </Form>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  label {
    color: #fff;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  button[type='submit'] {
    margin-top: 20px;
  }
`

const DseUploadForm = Form.create<DseUploadFormProps>({
  onValuesChange: ({ form, onChange }, changed, all) => {
    onChange && onChange(all)
  },
})(DseUploadFormFC)

export { DseUploadForm }
